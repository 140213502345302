<template>
  <dashboard-content full-width>
    <v-row>
      <!-- Columns: 
        Client name
        Client phone
        Client email
        Caregiver name
        Caregiver phone
        Caregiver email
      -->
      <v-col class="col-12 py-0">
        <h1 class="mb-4">
          Clients Summary
        </h1>
        <firebase-table
          :style="{ 'cursor': 'pointer' }"
          :headers="headers"
          :collection="'users/' + user.id + '/clients'"
          :counter-path="counterPath"
          :counter-field="counterField"
          :prepared-collection="preparedCollection"
          :table-name="'clients'"
        />
      </v-col>
    </v-row>
  </dashboard-content>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DashboardContent from '@/components/dashboard/DashboardContent'
import FirebaseTable from '@/components/FirebaseTable'

export default {
  components: {
    FirebaseTable,
    DashboardContent
  },
  data() {
    return {
      headers: [
        { text: 'Client name', value: 'name' },
        { text: 'Client phone', value: 'phone' },
        { text: 'Client email', value: 'email' },
        { text: 'Caregiver name', value: 'caregiverName' },
        { text: 'Caregiver phone', value: 'caregiverPhone' },
        { text: 'Caregiver email', value: 'caregiverEmail' }
      ],
      preparedCollection: null,
      counterPath: null,
      counterField: null
    }
  },
  computed: {
    ...mapGetters('authentication', ['user', 'isProvider']),
    ...mapGetters('clients', ['client', 'haveChosenClient'])
  },
  created() {
    // call this to ensure all clients have 'isDeleted' field
    this.fetchClients()
    
    this.counterPath = `users/${this.user.id}`
    this.counterField = 'countClients'
    this.preparedCollection = this.$firebase
      .firestore()
      .collection(`users/${this.user.id}/clients`)
      .where('isDeleted', '==', false)
  
  },
  methods: {
    ...mapActions('clients', ['fetchClients']),
  }
}
</script>